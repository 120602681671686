<template>
  <div ref="container" @dblclick="fullscreenSwich"
       style="width:100%; height: 100%; background-color: #000000;margin:0 auto;position: relative;">
    <div style="color: #fff">{{ text }}</div>
    <div class="buttons-box" id="buttonsBox">
      <div class="buttons-box-left">

        <a-icon v-if="!playing" type="caret-right" style="margin-left: 8px; font-size: 16px" @click="playBtnClick" />
        <a-icon v-if="playing" type="pause" style="margin-left: 8px; font-size: 16px" @click="pause" />
        <a-icon type="close-circle" theme="filled" style="margin-left: 10px; font-size: 16px" @click="destroy" />
<!--        <i v-if="isNotMute" class="iconfont icon-audio-high jessibuca-btn" @click="mute()"></i>-->
<!--        <i v-if="!isNotMute" class="iconfont icon-audio-mute jessibuca-btn" @click="cancelMute()"></i>-->
      </div>
      <div class="buttons-box-right">
        <span class="jessibuca-btn">{{ kBps }} kb/s</span>
        <a-icon  type="video-camera" theme="filled"  style="margin-left: 8px; font-size: 16px"  @click="screenshot"/>
        <a-icon type="retweet" @click="playBtnClick"   style="margin-left: 8px ; font-size: 16px" />
        <a-icon v-if="!fullscreen" type="arrows-alt" @click="fullscreenSwich" style="margin-left: 8px; font-size: 16px" />
        <a-icon v-if="fullscreen" type="shrink" @click="fullscreenSwich" style="margin-left: 8px; margin-right: 8px; font-size: 16px"/>

      </div>
    </div>
  </div>
</template>

<script>
import {axiosServer} from "@/utils/request";

let jessibucaPlayer = {};
export default {
  name: 'JessibucaDemo',
  data() {
    return {
      playing: false,
      isNotMute: false,
      quieting: false,
      fullscreen: false,
      loaded: false, // mute
      speed: 0,
      performance: "", // 工作情况
      kBps: 0,
      btnDom: null,
      videoInfo: null,
      volume: 1,
      rotate: 0,
      vod: true, // 点播
      forceNoOffscreen: false,
      text: ""
    };
  },
  props: ['videoUrl', 'error', 'hasAudio', 'height'],
  created() {
    // let paramUrl = decodeURIComponent(this.$route.params.url)
    this.$nextTick(() => {
      this.updatePlayerDomSize()
      window.onresize = this.updatePlayerDomSize
      // if (typeof (this.videoUrl) == "undefined") {
      //   this.videoUrl = paramUrl;
      // }
      this.btnDom = document.getElementById("buttonsBox");
    })
  },
  // mounted() {
  //   const ro = new ResizeObserver(entries => {
  //     entries.forEach(entry => {
  //       this.updatePlayerDomSize()
  //     });
  //   });
  //   ro.observe(this.$refs.container);
  // },
  watch: {
    videoUrl: {
      handler(newVal, oldVal) {
        console.log("alarm videoUrl",newVal, oldVal)
        this.$nextTick(() => {
          if (newVal !== oldVal){
            this.play(newVal);
            this.pushCamera(newVal);
            this.stopCamera(oldVal);
          }

        })
      },
      immediate: true
    }
  },
  methods: {
    updatePlayerDomSize() {
      let dom = this.$refs.container;
      let width = dom.parentNode.clientWidth
      let height = (9 / 16) * width
      console.log(height)

      console.log(dom.clientHeight)
      if (height > dom.clientHeight) {
        height = dom.clientHeight
        width = (16 / 9) * height
      }
      if (width > 0 && height > 0) {
        dom.style.width = width + 'px';
        dom.style.height = height + "px";
        console.log(width)
        console.log(height)
      }
    },
    create() {
      let options = {
        container: this.$refs.container,
        autoWasm: true,
        background: "",
        controlAutoHide: false,
        debug: false,
        decoder: "decoder.js",
        forceNoOffscreen: false,
        hasAudio: typeof (this.hasAudio) == "undefined" ? true : this.hasAudio,
        heartTimeout: 5,
        heartTimeoutReplay: true,
        heartTimeoutReplayTimes: 3,
        hiddenAutoPause: false,
        hotKey: true,
        isFlv: false,
        isFullResize: false,
        isNotMute: this.isNotMute,
        isResize: false,
        keepScreenOn: true,
        loadingText: "请稍等, 视频加载中......",
        loadingTimeout: 10,
        loadingTimeoutReplay: true,
        loadingTimeoutReplayTimes: 3,
        openWebglAlignment: false,
        operateBtns: {
          fullscreen: false,
          screenshot: false,
          play: false,
          audio: false,
          record: false
        },
        recordType: "mp4",
        rotate: 0,
        showBandwidth: false,
        supportDblclickFullscreen: false,
        timeout: 10,
        useMSE: true,
        // useWCS: location.hostname === "localhost" || location.protocol === "https:",
        useWebFullScreen: true,
        videoBuffer: 0.1,
        wasmDecodeErrorReplay: true,
        wcsUseVideoRender: true
      };
      console.log("Jessibuca -> options: ", options);
      jessibucaPlayer[this._uid] = new window.Jessibuca({...options});

      let jessibuca = jessibucaPlayer[this._uid];
      let _this = this;
      jessibuca.on("pause", function () {
        _this.playing = false;
      });
      jessibuca.on("play", function () {
        _this.playing = true;
      });
      jessibuca.on("fullscreen", function (msg) {
        _this.fullscreen = msg
      });
      jessibuca.on("mute", function (msg) {
        _this.isNotMute = !msg;
      });
      jessibuca.on("performance", function (performance) {
        let show = "卡顿";
        if (performance === 2) {
          show = "非常流畅";
        } else if (performance === 1) {
          show = "流畅";
        }
        _this.performance = show;
      });
      jessibuca.on('kBps', function (kBps) {
        _this.kBps = Math.round(kBps);
      });
      jessibuca.on("videoInfo", function (msg) {
        console.log("Jessibuca -> videoInfo: ", msg);
      });
      jessibuca.on("audioInfo", function (msg) {
        console.log("Jessibuca -> audioInfo: ", msg);
      });
      jessibuca.on("error", function (msg) {
        console.log("Jessibuca -> error: ", msg);
      });
      jessibuca.on("timeout", function (msg) {
        console.log("Jessibuca -> timeout: ", msg);
      });
      jessibuca.on("loadingTimeout", function (msg) {
        console.log("Jessibuca -> timeout: ", msg);
      });
      jessibuca.on("delayTimeout", function (msg) {
        console.log("Jessibuca -> timeout: ", msg);
      });
      jessibuca.on("playToRenderTimes", function (msg) {
        console.log("Jessibuca -> playToRenderTimes: ", msg);
      });
    },
    playBtnClick: function (event) {
      console.log(event)
      if (this.videoUrl){
        this.play(this.videoUrl);
      }

    },
    play: function (url) {
      console.log("Jessibuca -> url: ", url);
      if (jessibucaPlayer[this._uid]) {
        this.destroy();
      }
      this.create();
      jessibucaPlayer[this._uid].on("play", () => {
        this.playing = true;
        this.loaded = true;
        this.quieting = true;
      });
      if (jessibucaPlayer[this._uid].hasLoaded()) {
        jessibucaPlayer[this._uid].play(url);
      } else {
        jessibucaPlayer[this._uid].on("load", () => {
          jessibucaPlayer[this._uid].play(url);
        });
      }
    },
    pause: function () {
      if (jessibucaPlayer[this._uid]) {
        jessibucaPlayer[this._uid].pause();
      }
      this.playing = false;
      this.err = "";
      this.performance = "";
    },
    screenshot: function () {
      if (jessibucaPlayer[this._uid]) {
        jessibucaPlayer[this._uid].screenshot();
      }
    },
    mute: function () {
      if (jessibucaPlayer[this._uid]) {
        jessibucaPlayer[this._uid].mute();
      }
    },
    cancelMute: function () {
      if (jessibucaPlayer[this._uid]) {
        jessibucaPlayer[this._uid].cancelMute();
      }
    },
    pushCamera(url){
      axiosServer({
        url: "/jc/jcGeneral/push/camera",
        type: "POST",
        params: {
          sendUrl: url
        }
      })
    },
    stopCamera(url){
      axiosServer({
        url: "/jc/jcGeneral/stop/camera",
        type: "POST",
        params: {
          sendUrl: url
        }
      })
    },
    destroy: function () {
      if (jessibucaPlayer[this._uid]) {
        jessibucaPlayer[this._uid].destroy();
      }
      if (document.getElementById("buttonsBox") == null) {
        this.$refs.container.appendChild(this.btnDom)
      }
      jessibucaPlayer[this._uid] = null;
      this.playing = false;
      this.err = "";
      this.performance = "";

    },
    fullscreenSwich: function () {
      let isFull = this.isFullscreen()
      jessibucaPlayer[this._uid].setFullscreen(!isFull)
      this.fullscreen = !isFull;
    },
    isFullscreen: function () {
      return document.fullscreenElement ||
          document.msFullscreenElement ||
          document.mozFullScreenElement ||
          document.webkitFullscreenElement || false;
    }
  },

  destroyed() {
    if (jessibucaPlayer[this._uid]) {
      jessibucaPlayer[this._uid].destroy();
    }
    this.playing = false;
    this.loaded = false;
    this.performance = "";
    // 销毁当前的视频url
    this.stopCamera(this.videoUrl);
  },
}
</script>

<style scoped>
.buttons-box {
  width: 100%;
  height: 28px;
  background-color: rgba(43, 51, 63, 0.7);
  position: absolute;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  left: 0;
  bottom: 0;
  user-select: none;
  z-index: 10;
  align-items: center;
  justify-content: space-between;
}

.jessibuca-btn {
  width: 20px;
  color: rgb(255, 255, 255);
  line-height: 27px;
  margin: 0px 10px;
  padding: 0px 2px;
  cursor: pointer;
  text-align: center;
  font-size: 0.8rem !important;
}

.buttons-box-left {


}
.buttons-box-right {

}
</style>