<template>
  <div class="video-box">
    <div class="top">
      <div class="video">
        <JessibucaAlarm v-if="currentVideoUrl.includes('wss')" :video-url="currentVideoUrl"></JessibucaAlarm>
        <jcWebrtcAlarmByBoli v-if="currentVideoUrl.includes('webrtc')" :video-url="currentVideoUrl"></jcWebrtcAlarmByBoli>
      </div>
      <div class="info">
                  <a-card class="alarm-list" style="width:300px;">
                    <div>设备名称: {{videoAlarm.device.deviceName}}</div>
                    <div>告警时间: {{videoAlarm.alarm.alarmTime}}</div>
                    <div>告警单位: {{videoAlarm.company.comName}}</div>
                    <div>告警类型: {{videoAlarm.alarm.alarmTypeText}}</div>
                    <div v-if="videoAlarm.device.deviceType == 2">告警值:{{videoAlarm.alarm.alarmVal}}</div>

<!--                    <div>设备名称: {{alarmList[0].deviceName}}</div>-->
<!--                    <div>告警时间: {{alarmList[0].alarmTime}}</div>-->
<!--                    <div>告警类型: {{alarmList[0].alarmType}}</div>-->
<!--                    <img :src="alarmList[0].pic">-->
                    <div style="margin-top: 5px" v-if="videoAlarm.device.deviceType == 1">
                      <img :src="videoAlarm.alarm.pictureUrl" v-if="videoAlarm.alarm.pictureUrl" :preview="videoAlarm.alarm.id" >
                      <a-empty style="width:260px; height: 180px;background-color: #787a85; color: #fff" description="暂无图片" v-else />

                    </div>
                    <a-button type="danger" style="width: 100%; margin-top: 8px" @click="clickFireExtinguishing(videoAlarm.alarm)">
                      一键灭火
                    </a-button>
                  </a-card>
      </div>
<!--      <div style="font-size: 16px; font-weight: bold">设备名称：xxx摄像头告警</div>-->


<!--      <div class="monitor-alarm">-->
<!--        <div v-for="item in alarmList" :key="item">-->
<!--          <a-card class="alarm-list">-->
<!--            <div>告警时间: {{item.alarmTime}}</div>-->
<!--            <div>告警类型: {{item.alarmType}}</div>-->
<!--            <img :src="item.pic">-->
<!--          </a-card>-->
<!--        </div>-->
<!--      </div>-->
    </div>
    <div class="device">
      <div class="monitors-list">
        <a-tag :color="activeNum === i ? 'green' : ''" v-for="(item, i) in videoAlarm.monitors" :key="i" @click="monitorClick(item, i)">
          {{ item.deviceName}}
        </a-tag>
      </div>
      <div class="device-control">
        <div>摄像头控制设备</div>
        <a-card class="item" v-for="(item,index) in orderDevice" :key="index">
          <div  >设备名称: {{item.deviceName}}</div>
          <!--            <div style=" margin-top: 5px">设备状态: {{item.status}}</div>-->
          <div style="margin-top: 5px">设备指令: </div>
          <div style="display: flex; margin-top: 8px">
            <div v-for="(it, i) in item.option" :key="i" style="margin-right: 5px">

              <template  v-if="item.needPassword === 1">
                <a-popconfirm
                    title="下发指令是一个危险的操作"
                    ok-text="下发"
                    cancel-text="取消"
                    @confirm="sendOrder(item, it)"
                >
                  <a-tag color="green" >
                    {{ it.name }}
                  </a-tag>
                </a-popconfirm>
              </template>
              <template v-else>
                <a-tag color="green" @click="sendOrder(item, it)">
                  {{ it.name }}
                </a-tag>
              </template>


            </div>
          </div>
        </a-card>
      </div>
      <div>关联物联设备</div>
      <div class="content1">


        <a-card class="item" v-for="(item,index) in deviceList" :key="index">
          <div  >设备名称: {{item.deviceName}}</div>
          <!--            <div style=" margin-top: 5px">设备状态: {{item.status}}</div>-->
          <div style="margin-top: 5px">设备指令: </div>
          <div style="display: flex; margin-top: 8px">
            <div v-for="(it, i) in item.option" :key="i" style="margin-right: 5px">

              <template  v-if="item.needPassword === 1">
                <a-popconfirm
                    title="下发指令是一个危险的操作"
                    ok-text="下发"
                    cancel-text="取消"
                    @confirm="sendOrder(item, it)"


                >
                  <a-tag color="green" >
                    {{ it.name }}
                  </a-tag>
                </a-popconfirm>
              </template>
              <template v-else>
                <a-tag color="green" @click="sendOrder(item, it)">
                  {{ it.name }}
                </a-tag>
              </template>


            </div>
          </div>
        </a-card>
      </div>
    </div>

  </div>
</template>
<script>
import JessibucaAlarm from "@/views/alarm/JessibucaAlarm.vue";
import jcWebrtcAlarmByBoli from "@/views/alarm/JcWebrtcAlarmByBoli.vue";
import {axiosServer} from "@/utils/request";
import {sendOrder} from "@/utils/commenRequset";

export default {
  name: "JcAlarmDialog",
  components:{
    JessibucaAlarm,
    jcWebrtcAlarmByBoli
  },
  props: [
      "videoAlarm",
      "videoAlarmId"
  ],
  mounted() {

  },
  data(){
    return{
      activeNum: 0,
      deviceList: [],
      orderDevice: [],
      currentVideoUrl: ""
    }
  },
  methods: {
    sendOrder,
    initRelationDevice(deviceId){
      this.orderDevice = [];
      axiosServer({
        url: "/jc/jcGeneral/relation/device/one",
        params: {
          deviceId: deviceId
        },
        type:"POST",
      }).then(res => {
        console.log("initRelationDevice：",res);
        if (res.success){
          this.orderDevice = res.result;
        }

      })
    },

    initDevice1(comId){

      axiosServer({
        url: "/jc/jcGeneral/relation/device1",
        params: {
          comId: comId || 0,
        },
        type:"POST",
      }).then(res => {
        console.log("initDevice1：",res);
        this.deviceList = res.result;
      })
    },
    monitorClick(record, i){
      this.currentVideoUrl = record.wssUrl;
      this.activeNum = i;
      this.initRelationDevice(record.id);
    },
    clickFireExtinguishing(record){
      axiosServer({
        url: "/jc/jcGeneral/fire/extinguishing",
        type: "POST",
        params: {
          deviceId: record.deviceId
        }
      }).then(res =>{
        if (res.success){
          this.$message.success(res.message)
        }else {
          this.$message.error(res.message)
        }
      }).catch(()=>{
        this.$message.error("下发错误")
      })
    }
  },


}
</script>



<style scoped lang="scss">
.video-box{
  padding: 10px;
  height: 735px;

  .top{
    width: 1000px;
    height:  400px;
    display: flex;
    .video{
      width: 660px;
      margin-right: 15px;
    }
    .info{
          img{
            width: 280px;
            height: 200px;
          }
          ::v-deep .ant-card-body{
            padding: 8px;
          }
          ::v-deep .ant-btn{
            padding: 0;
          }
    }
  }
  .device{
    padding-top: 5px;
    ::v-deep .ant-card-body{
      padding: 0;
    }
    .monitors-list{
      margin: 5px 0;

      display: flex;
    }
        //background-color: #f5222d;
    .content1{

          //display: flex;
          height: 160px;
          overflow: hidden;
          overflow-y: scroll;
        }
        .content1::-webkit-scrollbar {
          width: 0px;

        }
        .content1::-webkit-scrollbar-thumb {
          border-radius: 0;
          background: rgba(0,0,0,0.2);
        }
        .content1::-webkit-scrollbar-track {
          border-radius: 0;
          background: rgba(0,0,0,0.1);
        }
        .item {
          margin-top: 5px;
          margin-right: 5px;
          padding: 5px;
          display: inline-block;
          background-color: rgb(13, 27, 88);
          color: #fff;
          //width: 100px;
          height: 95px;
        }
  }
  //.video{
  //  width: 650px;
  //  height:  400px;
  //  //background-color: #ff5722;
  //  margin-right: 15px;
  //  .device{
  //    padding-top: 5px;
  //
  //    //background-color: #f5222d;
  //    .content1{
  //      //display: flex;
  //      height: 250px;
  //      overflow: hidden;
  //      overflow-y: scroll;
  //    }
  //    .content1::-webkit-scrollbar {
  //      width: 0px;
  //
  //    }
  //    .content1::-webkit-scrollbar-thumb {
  //      border-radius: 0;
  //      background: rgba(0,0,0,0.2);
  //    }
  //    .content1::-webkit-scrollbar-track {
  //      border-radius: 0;
  //      background: rgba(0,0,0,0.1);
  //    }
  //    .item {
  //      margin-top: 5px;
  //      margin-right: 5px;
  //      display: inline-block;
  //      background-color: rgb(13, 27, 88);
  //      color: #fff;
  //      //width: 100px;
  //      height: 120px;
  //    }
  //  }
  //}
  //.alarm{
  //  width: 300px;
  //  padding: 5px;
  //  //height:  650px;
  //
  //  .alarm-list{
  //    background-color: transparent;
  //
  //    ::v-deep .ant-card-body{
  //      padding: 8px;
  //    }
  //    ::v-deep .ant-btn{
  //      padding: 0;
  //    }
  //  }
  //
  //}
  //.monitor-alarm{
  //  width: 310px;
  //  padding: 5px;
  //  .alarm-list{
  //    background-color: transparent;
  //    img{
  //      width: 280px;
  //      height: 200px;
  //    }
  //    ::v-deep .ant-card-body{
  //      padding: 8px;
  //    }
  //    ::v-deep .ant-btn{
  //      padding: 0;
  //    }
  //  }
  //}
}
</style>