import {axiosServer} from "@/utils/request";
import Vue from "vue";

console.log(Vue.prototype)

export const sendOrder = (data, order) => {

    // if (order.orderType === 1){
    //     axiosServer({
    //         url: "/jc/jcGeneral/auto/send",
    //         params: {
    //             ip: data.ip,
    //             val: order.val,
    //             orderType: order.orderType,
    //
    //         },
    //         type:"POST",
    //     }).then(res => {
    //         if (res.code === 200){
    //             Vue.prototype.$notification.success({
    //                 message:res.message
    //             });
    //         }else {
    //             Vue.prototype.$notification.error({
    //                 message:res.message
    //             });
    //         }
    //     })
    //     return;
    // }

    let config = {};

    if (data.actionType === 1){
        config.ip = data.ip;
        config.operationCode = order.val;
    }
    if (data.actionType === 2){

        config.ip = data.ip;
        config.eqSwitch = order.val;
        config.equipId =  data.equipid;
    }

    axiosServer({
        url: "/jc/jcGeneral/send/order",
        params: {
            ...config
        },
        type:"POST",
    }).then(res => {
        if (res.code === 200){
            Vue.prototype.$notification.success({
                message:res.message
            });
        }else {
            Vue.prototype.$notification.error({
                message:res.message
            });
        }
    })


}