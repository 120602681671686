<template>
  <div class="monitor">
    <div class="monitor-box">
      <div v-show="textKey === 1" style="display: flex;justify-content: center; font-weight: bold">实时监控</div>
      <div v-show="textKey === 2">录像</div>
    </div>
    <div >
      <template v-if="type === 100">
        <div class="amap-page-container">
          <el-amap  :center="center" :zoom="zoom"
                     class="amap-demo"

                    mapStyle="amap://styles/darkblue">
            <el-amap-marker

                :position="marker.position"

                :zIndex="5"
                icon="https://jc-app-store-1258271165.cos.ap-guangzhou.myqcloud.com/icon%2Fmark_normal.png"></el-amap-marker>
          </el-amap>
        </div>
      </template>
      <template v-if="playVideoUrl && type === 1">
        <div>摄像头状态：{{monitorStatus ? "在线":"离线"}}</div>
        <jessibucaDemo :video-url="playVideoUrl"  @monitorStatus="getMonitorStatus"></jessibucaDemo>
      </template>
      <imageVideo :video-url="playVideoUrl" v-if="playVideoUrl && type === 2"></imageVideo>
      <jcWebrtcByBoli ref="webrtc" :video-url="playVideoUrl"  v-if="playVideoUrl && type === 3" :status-show="true"></jcWebrtcByBoli>
      <div class="content1">
        <a-card class="item" v-for="(item) in orderDevice" :key="item.id">
          <div  :title="item.deviceName">名称: {{item.deviceName.replace("摄像头", "...")}}</div>
          <!--         <div style=" margin-top: 5px">设备状态: {{item.status}}</div>-->
          <div style=" margin-top: 5px">设备指令: </div>
          <div style="display: flex; margin-top: 8px">
            <div v-for="(it, i) in item.option" :key="i" style="margin-right: 5px">
              <template  v-if="item.needPassword === 1">
                <a-popconfirm
                    title="下发指令是一个危险的操作"
                    ok-text="下发"
                    cancel-text="取消"
                    @confirm="sendOrder(item, it)"
                    @cancel="cancelOrder"

                >
                  <a-tag color="green" >
                    {{ it.name }}
                  </a-tag>
                </a-popconfirm>
              </template>
              <template v-else>
                <a-tag color="green" @click="sendOrder(item, it)">
                  {{ it.name }}
                </a-tag>
              </template>





            </div>
          </div>
        </a-card>
      </div>

<!--      <iframe width="910" height="500"  id="myframe" :src="playVideoUrl" v-if="playVideoUrl && type === 3"></iframe>-->
      <a-empty style="height: 610px;background-color: #0f1325" description="暂无视频" v-if="type === 0"/>
      <a-modal
          style="z-index: 9999!important;"
          width="1430px"
          :visible="videoDialogVisible"
          @cancel="videoCancel"
          @ok="videoOk"
          :footer="null"
          :centered="true"
          :maskClosable="false"
      >

        <iframe width="1366" height="768"  id="myframe" :src="playVideoUrl" ></iframe>


      </a-modal>

    </div>


  </div>
</template>

<script>
import jessibucaDemo from "@/views/monitor/JessibucaDemo.vue";
import imageVideo from "@/views/monitor/ImageVideo.vue";
import jcWebrtcByBoli from "@/views/monitor/JcWebrtcByBoli.vue";
import {axiosServer} from "@/utils/request";
import {sendOrder} from "@/utils/commenRequset";
export default {
  name: "JcMonitor",
  components: {

    jessibucaDemo,
    imageVideo,
    jcWebrtcByBoli,

  },
  props:["playVideoUrl"],
  data(){
    return{
      textKey: 1,
      monitorUrl: null,
      type: 0,
      webRtcServer: null,
      monitorStatus: false,
      videoDialogVisible: false,
      center: [114.12374, 22.670216],
      zoom: 18,
      marker: {
        position: [114.12374, 22.670216]
      },
      orderDevice:[]
    }
  },
  mounted() {

  },
  methods:{
    sendOrder,
    getMonitorStatus(status){

      this.monitorStatus = status;

    },
    videoCancel(){
      this.videoDialogVisible = false;
    },
    videoOk(){
      this.videoDialogVisible = false;
    },
    webrtcClose(){
      if (this.type === 3){
        this.$refs.webrtc.closeVideo();
      }

    },
    clickCompanyChange(position){
      console.log('position',position)
      if (position[0] === 0 || position[1] === 0){
        this.type = 0;
        return;
      }

      this.marker.position =  position;
      this.center = position;
      this.type = 100;
    },
    initRelationDevice(deviceId){
      this.orderDevice = [];
      axiosServer({
        url: "/jc/jcGeneral/relation/device/one",
        params: {
          deviceId:deviceId
        },
        type:"POST",
      }).then(res => {
        console.log("initRelationDevice：",res);

        this.orderDevice = res.result;
      })
    },

    cancelOrder(){

    },
},
  watch: {

    playVideoUrl: {
      handler(newVal) {
        this.monitorStatus = false;
        console.log("watch",newVal)
        if (newVal){
          if (newVal.includes("120.226.39.61")){
            this.type = 0;
            // this.videoDialogVisible = true;
            window.open(newVal, '_blank', '');
            return;
          }

          if (newVal.includes("webrtc")){
            this.type = 3;
            return;
          }

          if (newVal.includes("main_stream.jpeg")){
            this.type = 2;
          }else {
            // 默认置1
            this.type = 1;
          }
        }else {
          this.type = 0;
        }

        // console.log("jcMonitor videoUrl",newVal, oldVal)
        // this.$nextTick(() => {
        //   this.play(val);
        // })
      },
      immediate: true
    }
  },
}
</script>
<style scoped lang="scss">
.monitor{
  padding: 15px;
  height: 700px;
  .monitor-box{
    font-size: 18px;
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
  }
}
#myframe{
  //transform: scale(2, 2);
}
.amap-demo {
  padding: 15px;
  height: 620px;
  width: 100%;
}

.content1{
  margin-top: 8px;
  display: flex;
  ::v-deep .ant-card-body{
    padding: 0;
  }
  .item{
    padding: 8px;
    //margin-top: 10px;
    margin-left: 5px;
    margin-right: 10px;
    display: inline-block;
    background-color: rgb(13, 27, 88);
    color: #fff;
    min-width: 150px;
    height: 95px;
  }
}
</style>