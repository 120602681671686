<template>
  <div class="video">
    <img id="IDvideo1" ref="imageVideo" style="position: absolute; width: 97%; height: 85%; display: block;" :src="actionUrl" />
  </div>
</template>

<script>
export default {
  name: "ImageVideo",
  props: ['videoUrl'],
  data(){
    return{
      g_prm: 100,
      t: null,
      framefreq: 25,
      g_EnableVideo: 1,
      actionUrl: null
    }
  },
  created() {
    this.actionUrl = this.videoUrl + this.g_prm;
  },
  mounted() {
    this.$refs.imageVideo.onload = this.showVideoFunction;
  },
  beforeDestroy() {
    this.clearData();
  },
  methods: {
    timedCount(){
      if (this.g_EnableVideo === 0) {
        return;
      }
      this.g_prm++;
      this.actionUrl = this.videoUrl + this.g_prm;
      this.$refs.imageVideo.src = this.actionUrl;
    },
    showVideoFunction()
    {
      // if (img.complete == true){
      //   document.getElementById("IDvideo1").src = img.src;
      //   document.getElementById("IDvideo1").style.display = "block";
      //
      //   this.t =  setTimeout("timedCount()",framefreq);
      // }
      this.t =  setTimeout(this.timedCount, this.framefreq);
    },
    clearData() {
      clearTimeout(this.t);
    }
  },


}
</script>



<style scoped lang="scss">
.video{
  //width: 600px;
}
</style>